import React from 'react';
import DetailBox from '../Common/DetailBox';
import ImageSrcSet from '../Common/imageSrcSet';
import {Helmet} from 'react-helmet';

const PostSingleBasic = (props) => {

    const post = props.post;
    const lang = props.lang

    return (
        <React.Fragment>
            <Helmet>
                <title>{post ? post[0].title.rendered : ""} - Visiti</title>
                <meta name="description" content={post ? post[0].excerpt.rendered.replace(/(<([^>]+)>)/ig,"") : ""} />
            </Helmet>
            {post ? post.map(item =>
                <div key={item.id} className="single basic">                   
                    {/* Note: Maybe ajust background position based on image size */}
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                {item.acf.image ? 
                                <ImageSrcSet 
                                    image={item.acf.image} 
                                    maxsize={item.acf.image.sizes.large} 
                                    src={item.acf.image.sizes.medium_large}
                                    smallSize={item.acf.image.sizes.medium}
                                    mediumSize={item.acf.image.sizes.medium_large} 
                                    largeSize={item.acf.image.sizes.large}
                                />
                                :""}
                                <h1 dangerouslySetInnerHTML={{ __html: item.title.rendered}}></h1>
                                <p dangerouslySetInnerHTML={{ __html: item.content.rendered }}></p>
                            </div>
                            <div className="col-12 col-lg-4">
                                <DetailBox item={item} lang={lang} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : ""}
        </React.Fragment>
    );
}

export default PostSingleBasic;