import {getTranslated} from '../Utils/language.js';

const Translate = (props) => {
    let {lang, name} = props;

    const text = getTranslated(lang, name);    

    return (text);
}
 
export default Translate;