import React, { Component } from 'react';
import { Config } from '../../config.js';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Translate from '../Common/Translate';

class TopMenu extends Component {
    state = {
        error: null,
        isLoaded: false,
        menu: false,
        focus: false,
        showHideMenu: "collapse",
        url_other_lang: ''
    };

    componentDidMount() {            
        this.pullMenu(this.props.lang);
        this.setOtherLangUrl();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lang !== this.props.lang) {
            this.pullMenu(this.props.lang);
        }
        this.setOtherLangUrl();
    }

    setOtherLangUrl() {
        const language = this.props.lang;
        let {url_other_lang} = this.state;
        const current_url = this.props.location.pathname;

        if (this.props.otherLangSlug){
            url_other_lang = (language === "en" ? "/fr/" : "/en/") + this.props.otherLangSlug;
        } else {            
            url_other_lang = (language === "en" ? "/fr/" : "/en/") + current_url.replace(/(\/fr\/|\/en\/|\/fr$|\/en$)/g, "");
            url_other_lang = url_other_lang.replace(/\/\//g, "/");
        }
        if (this.state.url_other_lang !== url_other_lang){
            this.setState({url_other_lang});
        }
    }

    pullMenu = (lang) => {
        let langQuery;
        

        if(lang !== this.state.lang){
            if (lang === 'fr') {            
                langQuery = "&lang=fr";
            } else {
                langQuery = "";
            }
        }  

        if (lang !== this.state.lang){
            axios.get(Config.apiMenu + '?menu=header-menu' + langQuery)
            .then(result => {                
                this.setState({ 
                    isLoaded: true, 
                    menu: result.data,                    
                });
            }).catch(error => {
                this.setState({
                    isLoaded: false,
                    error: error
                })
            });
        }
    }

    toggleNavMenu = () => {
        const showHideMenu = (this.state.showHideMenu === "collapse") ? "" : "collapse";
        this.setState( { showHideMenu } );
    }

    toggleMenu = () => {
        if (this.state.showHideMenu === "") {
            this.setState( {showHideMenu : "collapse"});
        }
    }

    render() {
        const { error, menu, showHideMenu, url_other_lang } = this.state;
        const language = this.props.lang;        

        if(error){
            return <div>Error: {error.message}</div>;
        }        

        return (
            <div className="tm-top-bar" id="tm-top-bar">
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg narbar-light">
                            <Link className="navbar-brand" to={"/" + language}>
                                <div style={{backgroundImage: "url(/img/visiti_logo_process.png)", height: "35px", backgroundPosition: "center center", backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "210px"}} alt="logo" />
                            </Link>
                            <div className="mr-auto ml-auto header-slogan-container">
                                <p className="header-slogan"><Translate name="Discover your New Brunswick" lang={language} /></p>
                            </div>
                            <button onClick={this.toggleNavMenu} type="button" id="nav-toggle" className="navbar-toggler" data-toggle="collapse" data-target="#mainNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div id="mainNav" style={{flexGrow: "0"}} className={showHideMenu + " navbar-collapse tm-bg-white"}>
                                <ul className="navbar-nav ml-auto">
                                    {menu ? menu.map(item => 
                                        <li className="nav-item" key={item.ID}>
                                            <Link onClick={this.toggleMenu} className="nav-link" to={ "/" + language + item.slug.replace(/(\?lang=fr|\?lang=en)/g,"")}>{item.title}</Link>
                                        </li>
                                    ) : ''}
                                    <li className="nav-item" key="language-menu">
                                        <Link onClick={this.toggleMenu} className="nav-link" to={url_other_lang}><Translate name="Other Language" lang={language} /></Link>
                                    </li>
                                </ul>
                            </div>                            
                        </nav>            
                    </div>
                </div>
            </div>
        );
    }
}

export default TopMenu;