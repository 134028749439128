import React, { Component } from 'react';
import InputField from '../Common/inputField';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import axios from 'axios';
import { Config } from '../../config.js';
import moment from 'moment';
import Joi from 'joi';
import Translate from '../Common/Translate';
import {getTranslated} from '../Utils/language.js';

class SearchForm extends Component {
    state = { 
        data: {
            //city: [],
            regions: [], // It's regions for not but it's going to be provinces that are going to be pulled.
            startdate: undefined,
            enddate: undefined
        },
        cities: [],
        locationCategories: [],
        errors: {},
        error: null,
        isLoaded: false,
    }

    constructor (props) {
        super(props);
        
        this.state.data = this.props.searchData ? this.props.searchData : this.state.data;
    }

    schema = {
        regions: Joi.array(),
        startdate: Joi.date().max(Joi.ref('enddate')),
        enddate: Joi.date().min(Joi.ref('startdate')),
    }
    
    componentDidMount() {
        //let cities = [];
        //const {data} = this.state
        // axios.get(Config.apiCities)
        // .then(result => {
        //     cities = result.data.map(item => ({value: String(item.id), label: item.name}));
        //     //If a city is set then set it properly in the data
        //     if (cities.length > 0) {
        //         data.city = cities.filter(item => data.city.map(city => String(city)).includes(item.value));
        //     }
        //     this.setState({
        //         isLoaded: true,
        //         cities,
        //         data
        //     });
        // }).catch(error=> {
        //     this.setState({
        //         isLoaded: false,
        //         error
        //     });
        // });

        axios.get(Config.apiCategory + (this.props.lang === 'en' ? '?per_page=30' : "?lang=" + this.props.lang + "&per_page=30" ))
        .then(result => {
            this.setState({
                isLoaded: true,
                locationCategories: result.data
            })                        
        }).catch(error => {    
            this.setState({
                isLoaded: false,
                error
            });            
        });
    }

    validate = () => {
        const options = {abortEarly: false};
        const result = Joi.validate(this.state.data, this.schema, options);
        if(!result.error) return null;

        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    };

    showFromMonth = () => {
        const {fromDate,toDate} = this.state;
        if(!fromDate) return ;

        if (moment(toDate).diff(moment(fromDate), 'months') < 2) {
            this.toDate.getDayPicker().showMonth(fromDate);
        }
    }

    handleFromChange = (fromDate) => {
        const data = {...this.state.data};
        data.startdate = fromDate;
        this.setState({data});
    }

    handleToChange = (toDate) => {        
        const data = {...this.state.data};
        data.enddate = toDate;
        this.setState({ data }, this.showFromMonth);
    }    

    handleSelectChange = (selectedOption) => {
        const data = {...this.state.data};        
        //data.city = selectedOption === null ? [] : selectedOption;
        data.regions = selectedOption === null ? [] : selectedOption;
        this.setState({data});
    }

    handleSubmit = () => {
        const data = {...this.state.data};

        const errors = this.validate();
        
        this.setState({errors: errors || {} });
        if (errors) return;        

        //Do a search.
        this.props.handleSearch(data);
    }

    handleClear = () => {

    }

    render() { 
        const {regions, startdate, enddate} = this.state.data;
        const modifiers = {start: startdate, end: enddate};
        const {lang} = this.props;
        const toText = getTranslated(lang, "Departure");
        const fromText = getTranslated(lang, "Arrival");

        const parentProvinceCategory = lang === 'en' ? Config.parentProvinceCategory : Config.parentProvinceCategoryFr;
        
        let filteredRegions = [];

        //Filter location categories.
        if (this.state.locationCategories){
            filteredRegions = this.state.locationCategories.filter(locationCategorie => (locationCategorie.parent === parentProvinceCategory && locationCategorie.count > 0));  //parentProvinceCategory is going to be provinces but for now as per lack of data it's going to be regions.
            filteredRegions = filteredRegions ? filteredRegions.map(item => ({value: String(item.id), label: item.name})) : [];
        }

        return (             
            <div className={`form-row tm-search-form ${this.props.className}`}>
                {/* <InputField                     
                formElementClass="tm-form-element-100"
                fontAwesomeIcon="map-marker-alt"
                id="city"
                placeholder={<Translate name="Type your destination" lang={lang} />}
                specialStyle={{zIndex: 1, left: "20px"}}
                >
                    <Select 
                    value={city ? (typeof(city[0]) === 'number' ? {label: "loading", value: 0} : city) : []}
                    name="city"
                    onChange={this.handleSelectChange}
                    options={this.state.cities ? this.state.cities : []}
                    className="form-control form-control-select"
                    placeholder={<Translate name="Type your destination" lang={lang} />}
                    isMulti={true}
                    isSearchable={true}
                    />
                </InputField> */}
                {!this.props.hideDestinations ? <InputField
                formElementClass="tm-form-element-100"
                fontAwesomeIcon="map-marker-alt"
                id="region"
                placeholder={<Translate name="Type the regions" lang={lang} />}
                specialStyle={{zIndex: 1, left: "20px"}}
                >
                    <Select 
                    value={regions ? (typeof(regions[0]) === 'number' ? {label: "loading", value: 0} : regions) : []}
                    name="region"
                    onChange={this.handleSelectChange}
                    options={filteredRegions ? filteredRegions : []}
                    className="form-control form-control-select"
                    placeholder={<Translate name="Type the regions" lang={lang} />}
                    isMulti={true}
                    isSearchable={true}
                    />
                </InputField>
                : "" }
                <InputField 
                formElementClass="tm-form-element-50"
                fontAwesomeIcon={["far","calendar-alt"]}
                id="check-in"
                placeholder={fromText}
                >
                    <DayPickerInput
                        value={startdate}
                        name="startdate"
                        className={{overlay: 'form-control'}}
                        placeholder={fromText}
                        format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [startdate, { startdate, enddate }],
                            disabledDays: { after: enddate },
                            toMonth: enddate,
                            modifiers,
                            numberOfMonths: 1,
                            onDayClick: () => this.enddate.getInput().focus(),
                        }}
                        inputProps={{
                            className: "form-control"
                        }}
                        onDayChange={this.handleFromChange}
                    />
                </InputField>
                <InputField 
                formElementClass="tm-form-element-50"
                fontAwesomeIcon={["far","calendar-alt"]}
                id="check-out"
                placeholder={toText}
                >
                    <DayPickerInput
                        ref={el => (this.enddate = el)}
                        value={enddate}
                        name="enddate"
                        placeholder={toText}
                        format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                        selectedDays: [startdate, { startdate, enddate }],
                        disabledDays: { before: startdate },
                        modifiers,
                        month: startdate,
                        fromMonth: startdate,
                        numberOfMonths: 1,
                        }}
                        inputProps={{
                            className: "form-control"
                        }}
                        onDayChange={this.handleToChange}
                    />
                </InputField>
                <div className="form-group tm-form-element tm-form-element-50">
                    <button onClick={this.handleSubmit} className="btn btn-primary tm-btn-search"><Translate name="Search" lang={lang} /></button>
                </div>
            </div>            
       );
    }
}
 
export default SearchForm;