import React, { Component } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Config } from '../../config';
import ImageSrcSet from '../Common/imageSrcSet';

export default class Blog extends Component {

  state = {
      isLoaded: false,
      error: false,
      post: false
  };

  componentDidMount() {
      const apiLink = this.props.article ? Config.apiArticle : Config.apiPage;

      axios.get(apiLink + '/?slug=' + this.props.match.params.slug + (this.props.lang === 'en' ? '' :'&lang=' + this.props.lang) )
      .then(result => {
          if (result.data && result.data.length > 0){
              this.setState({ 
                  isLoaded: true, 
                  post: result.data[0]
              });

              this.props.modifyOtherLangSlug(result.data[0].other_lang_slug);
          }  else {
              //Not found
              this.props.history.push('/not-found');
          }
      }).catch(error => {
          this.setState({
              isLoaded: false,
              error: error
          })
      });
  }

  render() {

    let { error, post } = this.state;

    //Always start at top
    window.scrollTo(0 ,0);

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    if(post){
      return (
        <React.Fragment>          
          <Helmet>
              <title>{post ? post.title.rendered : ""} - Visiti</title>
              <meta name="description" content={post ? post.excerpt.rendered.replace(/(<([^>]+)>)/ig,"") : ""} />            
          </Helmet>
          <div style={{backgroundColor: "#E4F2EF", boxShadow: "0px 1px 3px 0px rgba(228,242,239,1)"}} >
              {post.acf.header_image ?                  
              <ImageSrcSet 
                  image={post.acf.header_image} 
                  maxsize={post.acf.header_image.sizes['2048x2048']} 
                  src={post.acf.header_image.sizes.medium_large}
                  smallSize={post.acf.header_image.sizes.medium_large}
                  mediumSize={post.acf.header_image.sizes.large} 
                  largeSize={post.acf.header_image.sizes['2048x2048']}                  
                  maxWidth={"1440px"}
                  height={"768px"}
              />
              :""}
          </div>
          <div className="row m-0">
            <div className="container article">
              <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered}}></h1>
              <p dangerouslySetInnerHTML={{__html: post.content.rendered}}></p>
            </div>
          </div>
        </React.Fragment>
      )
    }

    return "";
  }
}
