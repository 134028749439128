import React, { Component } from 'react'
import HomeListing from './../Listing/HomeListing';
import HomeSearch from './../Search/HomeSearch';
import Banner from './../Common/Banner';
// import BlogListing from '../Listing/BlogListing';
// import FooterContact from './../Form/FooterContact';

export default class Home extends Component {  
  render() {
    const {lang, history} = this.props;

    //Always start at top
    window.scrollTo(0 ,0);

    return (
        <React.Fragment>
            <HomeSearch lang={lang} history={history} />

            <Banner 
              /* Change What is necessary to change the banner also include the images in the public folder. */
              lang={lang}
              link={"https://www.acadienouvelle.com/concours/500-en-essence/"}
              startdate={"2023-06-12"}
              enddate={"2023-09-01"}
              imgLargeFr={"/img/contestvisiti2023_728x90.png"}
              imgMediumFr={"/img/contestvisiti2023_600x400.png"}
              imgSmallFr={"/img/contestvisiti2023_300x250.png"}
              imgLargeEn={"/img/contestvisiti2023_728x90.png"}
              imgMediumEn={"/img/contestvisiti2023_600x400.png"}
              imgSmallEn={"/img/contestvisiti2023_300x250.png"}
            />

            {/* <BlogListing lang={lang} perPage={3}/> */}

            <HomeListing lang={lang} />

            {/* <FooterContact lang={lang} /> */}
        </React.Fragment>
    )
  }
}