import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import './lib/css/bootstrap.min.css';
import './lib/css/datepicker.css';
import './lib/css/style.css';
import './lib/css/tooplate-style.css';
import './fontawesome';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { Router } from 'react-router-dom';

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
    ReactGA.initialize("G-4H0GWN0DRK");
    history.listen(location =>{
        ReactGA.send({hitType: "pageview", page: location.pathname});
    })
}

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();