import React from 'react';
import {Link} from 'react-router-dom';
import Translate from './Translate';

const SearchResult = (props) => {
    const {imageUrl, title, city, description, url, offertype, premium, phone_number, location, start_date, end_date, website } = props.commerce;
    let offertypelist = props.offertypelist;
    let current_offer_category = 'post';

    for(let i = 0; i < offertypelist.length; i++){
        if(offertypelist[i].id === offertype[0]){
            current_offer_category = offertypelist[i].slug;
        }
    }

    return (
        <div className={ premium === '1' ? "tm-recommended-place pp" : "tm-recommended-place bp" }>
            {( premium === "1" || premium === "2" ) && imageUrl ?
            <div className={"img-fluid card-img-top " + ( premium === "1" ? "pp" : "bp")} style={{ backgroundImage : "url(" + imageUrl.sizes.medium + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center" }} >
                    <img style={{display: "none"}} src={imageUrl.sizes.medium} alt={imageUrl.alt} />
            </div>
            : ""}
            <div className="tm-recommended-description-box">
                <h3 className="tm-recommended-title" dangerouslySetInnerHTML={{ __html: title }}></h3>
                <p className="tm-text-highlight">{city}</p>
                { premium === '1' ?  (<p dangerouslySetInnerHTML={{ __html: description }}></p>) : (<p></p>) }
                { premium === "1" || premium === "2" ?
                <Link to={ '/' + props.lang + '/' + current_offer_category + '/' + url } className="text-uppercase btn-primary tm-btn-primary">
                    <Translate name="View details" lang={props.lang} />
                </Link>
                : 
                    <div>
                        <Translate name="Phone: " lang={props.lang} />{phone_number}<br />
                        <Translate name="Address: " lang={props.lang} /><p dangerouslySetInnerHTML={{ __html: location }} />
                        { start_date !== "" && end_date !== "" ? <React.Fragment><Translate name="Months of operation" lang={props.lang} /><p>{start_date} <Translate name="to" lang={props.lang} /> {end_date}</p></React.Fragment> : ""}
                        { website !== "" ? <React.Fragment><Translate name="Website: " lang={props.lang} /> <p><a href={website}>{website}</a></p></React.Fragment> : "" }
                    </div>
                }
            </div>                        
        </div> 
    );
}
 
export default SearchResult;