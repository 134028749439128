let api;

//if (process.env.NODE_ENV !== 'development') {
    api = "https://api.visiti.ca/wp-json/";
//} else {
//    api = "http://api.visiti.localhost/wp-json/";
//}

export const Config = {
    apiUrl: api,
    apiPost:     api + 'wp/v2/posts',
    apiPage:     api + 'wp/v2/pages',
    apiArticle:  api + 'wp/v2/article',
    apiMenu:     api + 'am_rest/v2/menus/',    
    apiAcf :     api + 'acf/v3',
    apiCategory: api + 'wp/v2/categories',
    apiOfferType: api + 'wp/v2/offer_type/',
    apiCities: api + 'wp/v2/city/',
    defaultLang: '',
    parentProvinceCategory: 5,
    parentProvinceCategoryFr: 15,    
    defaultProvinceCategory: 11,
    numberSearchResultPerPage: 10,
};