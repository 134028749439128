import React from 'react';
import BlogListing from '../Listing/BlogListing';
import Translate from '../Common/Translate';
import { Helmet } from 'react-helmet';

const BlogArchive = (props) => {
    const {lang, history, location} = props;
    
    window.scrollTo(0, 0);

    return ( 
        <React.Fragment>
            {lang === "fr" ? 
                <Helmet>
                    <title>Archive articles - Visiti</title>
                    <meta name="description" content="Page avec une liste des articles du site" />
                </Helmet>
            :
                <Helmet>
                    <title>Article archive - Visiti</title>
                    <meta name="description" content="Page with a list of articles of the site." />
                </Helmet>
            }
            <div className="blog-archive-header">
                <div className="container">
                    <h1><Translate name="Article Archive" lang={lang} /></h1>
                </div>
            </div>
            <BlogListing 
                lang={lang}
                perPage={12}
                location={location}
                history={history}
                pagination={true}
            />            
        </React.Fragment>
    );
}
 
export default BlogArchive;