import React from 'react';
import moment from 'moment';

export default function Banner(props) {
    const {lang, link, startdate, enddate, imgLargeFr, imgMediumFr, imgSmallFr, imgLargeEn, imgMediumEn, imgSmallEn} = props;

    //Check if it should be displayed.
    const startdatetemp = moment(startdate, "YYYY-MM-DD");
    const enddatetemp = moment(enddate, "YYYY-MM-DD");

    if(moment().isBetween(startdatetemp, enddatetemp)) {
        return (
            <div className="banner-container">
                <a className={"banner-link"} href={link}>
                    <picture className={"banner-img"}>
                        <source media="(min-width:1000px" srcSet={(lang ==="fr" ? imgLargeFr : imgLargeEn)}/>
                        <source media="(min-width:600px" srcSet={(lang ==="fr" ? imgMediumFr : imgMediumEn)}/>
                        <img className={"banner-img"} alt="Banner" src={(lang === "fr" ? imgSmallFr : imgSmallEn)}/>
                    </picture>
                </a>
            </div>
        );
    } else {
        return ""
    }
}