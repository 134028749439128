export function GenerateQueryString(searchData, currentPage, categoryChange = true) {        
    let startString;            
    //Build query elements.

    //Check if resetting the page is required
    if (categoryChange){
        currentPage = 1;
    }

    let queryString = startString = "?page=" + currentPage;
            
    Object.keys(searchData).forEach((item) => {
        queryString += makeQueryParameter(searchData[item].queryLabel, searchData[item].values);
    });        

    //Empty query string if nothing was added
    if (queryString.length === startString.length ){
        queryString = "";
    }

    return queryString;    
}

function makeQueryParameter(name, queryItems) {
    let tempString = "";
    if (queryItems && queryItems.length > 0) {
        tempString = queryItems.reduce((final, current) => final === "" ? final = current : final = final + "," + current);
        return "&" + name + "=" + tempString;
    } else {
        return "";
    }
}