import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import TopMenu from '../Menu/TopMenu';
import Home from '../Pages/Home';
import Search from '../Pages/Search';
import Footer from '../Layout/Footer';
import NothingFound from '../Pages/NothingFound';
import Post from '../Pages/Post';
import Blog from '../Pages/Blog'; // Blog are every page with with a slug atm
import BlogArchive from './BlogArchive';
import {Helmet} from 'react-helmet';

export default class Page extends Component {
  state = {
    otherLangSlug: ""
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname){
      this.setState({otherLangSlug: ""});
    }
  }

  modifyOtherLangSlug = (otherLangSlug) => {
    this.setState({otherLangSlug});
  }
  
  render() {    
    const language = this.props.lang;

    return (
      <React.Fragment>
        {language === "fr" ? 
          <Helmet>
            <html lang={language} />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />            
            <title>Visiti</title>
            <meta name="description" content="Visiti.ca est l’outil par excellence pour planifier vos vacances au Nouveau-Brunswick. Notre site vous donne accès aux meilleurs établissements d’hébergement, restaurants, attractions, rendez-vous et activités du Nouveau-Brunswick." />
          </Helmet>
        :
          <Helmet>
            <html lang={language} />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>Visiti</title>
            <meta name="description" content="Visiti.ca is the ultimate tool for planning your vacation in New Brunswick. Our website gives you access to the best accommodations, restaurants, attractions, events, and activities in New Brunswick." />
          </Helmet>
        }
        <Helmet>
          <title>Visiti</title>
          <meta name="description" content="Site " />
        </Helmet>
        <TopMenu {...this.props} lang={language} otherLangSlug={this.state.otherLangSlug} />
        <div className="content">
          <Switch>
            <Route path="(/fr|)/recherche" render={(props)=><Search key={language+"-search"} {...props} lang={language} /> } />
            <Redirect path="/en/recherche" to="/en/search" />
            <Route path="/en/search" render={(props)=><Search key={language+"-search"} {...props} lang={language} /> } />
            <Redirect path="(/fr|)/search" to="/fr/recherche" />

            <Route exact path="(/fr|/en)/article" render={(props)=><BlogArchive key={language + "-archive-article"} {...props} lang={language} />} />
            <Route exact path="(/fr|/en)/article/:slug" render={(props)=><Blog key={language + "-" + this.props.match.params.slug + "-article"} {...props} lang={language} modifyOtherLangSlug={this.modifyOtherLangSlug} article={true} />} />
            <Route exact path="(/fr|/en)/:slug" render={(props)=><Blog key={language+ "-" + props.match.params.slug +"-blog"} {...props} lang={language} modifyOtherLangSlug={this.modifyOtherLangSlug} />} />
            <Route exact path="(/fr|/en)/:cat/:slug" render={(props)=><Post key={language + "-" + props.match.params.slug + "-post"} {...props} lang={language} modifyOtherLangSlug={this.modifyOtherLangSlug} />} />
            <Route exact path="(/fr|/en|/)" render={(props)=><Home key={language+"-home"} {...props} lang={language} />} />

            <Route path="(/fr|)/pas-trouver" render={(props)=><NothingFound key={language+"-nothingfound"} {...props} lang={language} />} />          
            <Route path="(/en|)/not-found" render={(props)=><NothingFound key={language+"-nothingfound"} {...props} lang={language} />} />
            
            {/* <Route path="/:slug" render={(props)=><Blog key={language+"-blog"} {...props} lang={language} />} /> */}
            <Redirect to="/not-found" />
          </Switch>
        </div>

        <Footer lang={language} />
      </React.Fragment>
    )
  }
}
