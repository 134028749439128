import React, {Component} from 'react';
import DetailBox from '../Common/DetailBox';
import ImageSrcSet from '../Common/imageSrcSet';
import {Helmet} from 'react-helmet';

class PostSinglePremium extends Component {
    state = {
        height: "768px"
    };

    render() {
        const {post, lang} = this.props;

        return (
        <React.Fragment>
            <Helmet>
                <title>{post ? post[0].title.rendered : ""} - Visiti</title>
                <meta name="description" content={post ? post[0].excerpt.rendered.replace(/(<([^>]+)>)/ig,"") : ""} />
            </Helmet>
            {post ? post.map(item =>
                <div key={item.id} className="single premium">
                    <div style={{backgroundColor: "#E4F2EF", boxShadow: "0px 1px 3px 0px rgba(228,242,239,1)"}} >
                    {item.acf.image ?                  
                    <ImageSrcSet 
                        image={item.acf.image} 
                        maxsize={item.acf.image.sizes['2048x2048']} 
                        src={item.acf.image.sizes.medium_large}
                        smallSize={item.acf.image.sizes.medium_large}
                        mediumSize={item.acf.image.sizes.large} 
                        largeSize={item.acf.image.sizes['2048x2048']}
                        height={this.state.height}
                        maxWidth={"1440px"}
                    />
                    :""}
                    </div>
                    <div className="container">
                        <div className="row mt-2">                            
                            {item.acf.images_premium && item.acf.images_premium.image1 && item.acf.images_premium.image2 && item.acf.images_premium.image3 && item.acf.images_premium.image4 ? 
                                <React.Fragment>
                                <div className="col col-md-3 col-6 p-0">
                                    <ImageSrcSet 
                                    image={item.acf.images_premium.image1} 
                                    maxsize={item.acf.images_premium.image1.sizes['medium-width']} 
                                    src={item.acf.images_premium.image1.sizes.medium}
                                    smallSize={item.acf.images_premium.image1.sizes.medium}
                                    mediumSize={item.acf.images_premium.image1.sizes.medium} 
                                    largeSize={item.acf.images_premium.image1.sizes.medium}
                                    height={"225px"}/>
                                </div>
                                <div className="col col-md-3 col-6 p-0">
                                    <ImageSrcSet 
                                    image={item.acf.images_premium.image2} 
                                    maxsize={item.acf.images_premium.image2.sizes['medium-width']} 
                                    src={item.acf.images_premium.image2.sizes.medium} 
                                    smallSize={item.acf.images_premium.image2.sizes.medium}
                                    mediumSize={item.acf.images_premium.image2.sizes.medium} 
                                    largeSize={item.acf.images_premium.image2.sizes.medium}
                                    height={"225px"}
                                    />
                                </div>
                                <div className="col col-md-3 col-6 p-0">
                                    <ImageSrcSet 
                                    image={item.acf.images_premium.image3} 
                                    maxsize={item.acf.images_premium.image3.sizes['medium-width']} 
                                    src={item.acf.images_premium.image3.sizes.medium} 
                                    smallSize={item.acf.images_premium.image3.sizes.medium}
                                    mediumSize={item.acf.images_premium.image3.sizes.medium} 
                                    largeSize={item.acf.images_premium.image3.sizes.medium}
                                    height={"225px"}
                                    />
                                </div>
                                <div className="col col-md-3 col-6 p-0">
                                    <ImageSrcSet 
                                    image={item.acf.images_premium.image4} 
                                    maxsize={item.acf.images_premium.image4.sizes['medium-width']} 
                                    src={item.acf.images_premium.image4.sizes.medium}
                                    smallSize={item.acf.images_premium.image4.sizes.medium}
                                    mediumSize={item.acf.images_premium.image4.sizes.medium} 
                                    largeSize={item.acf.images_premium.image4.sizes.medium}
                                    height={"225px"}
                                    />
                                </div>
                                </React.Fragment>
                             : ""}
                        </div>
                        <div className="row">
                            <div className="featured-image-selection col col-sm-12">
                                { /* Place smaller image here */ }
                                <h1 dangerouslySetInnerHTML={{ __html: item.title.rendered}}></h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <p dangerouslySetInnerHTML={{ __html: item.content.rendered }}></p>
                            </div>
                            <div className="col-12 col-md-5">
                                <DetailBox item={item} lang={lang} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : ""}
        </React.Fragment>
        );
    }
}

export default PostSinglePremium;