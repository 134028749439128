import React, { Component } from 'react';
import { Config } from '../../config.js';
import axios from 'axios';
import Commerce from '../Common/CommerceCard';
import {processPosts} from '../Utils/RequestData';
import {Link} from 'react-router-dom';
import Translate from '../Common/Translate';

class HomeListing extends Component {

    state = {
        error: null,
        isLoaded: false,
        provinces: false,
        currentProvince: false,
        commerces: [],
        offerTypes: false,
    };

    componentDidMount() {
        //let currentTabLocal;
        let commerces = false;

        
        axios.get(Config.apiOfferType + (this.props.lang === 'en' ? '' : "?lang=" + this.props.lang))
        .then(result => {
            this.setState({
                isLoaded: true,
                offerTypes: result.data
             });
        }).catch(error => {
            this.setState({
                isLoaded: false,
                error
            });
        });

        // axios.get(Config.apiCategory + '/?parent=' + Config.parentProvinceCategory)
        // .then(result => {
        //     if (this.state.currentTab) {
        //         currentTabLocal = this.state.currentProvince;
        //     } else {
        //         currentTabLocal = result.data[0].id
        //     }

        //     this.setState({
        //         isLoaded: true, 
        //         provinces: result.data,
        //         currentProvince: currentTabLocal
        //     });
        // }).catch(error => {
        //     this.setState({
        //         isLoaded: false,
        //         error: error
        //     })
        // });

        axios.get(Config.apiPost + '/?per_page=16&home=true&skip_cache=1' + (this.props.lang === 'en' ? '' : '&lang=' + this.props.lang) )
        .then ( result => {        
            commerces = processPosts(result);

            this.setState({commerces});
        }).catch(error => {
            this.setState({
                isLoaded: false,
                error: error
            })
        });              
    }

    handleChange = (provinceId) => {        
        let currentProvince = this.state.currentProvince;
        currentProvince = provinceId;
        let commerces = false;

        axios.get(Config.apiPost + '/?perPage=4&categories=' + currentProvince)
        .then(result => {            
            commerces = processPosts(result);

            this.setState({commerces, currentProvince});
        }).catch(error => {
            this.setState({
                isLoaded: false,
                error: error
            })
        });
    }

    render() {
        const { error, offerTypes } = this.state;
        const {lang} = this.props;

        if(error){
            return <div>Error: {error.message}</div>;
        }

        return (
            <div className="tm-container-outer tm-bg-gray" id="tm-section-3">
                <div className="tab-content clearfix">
                    <div className="container fade show active" id="4a">
                        <div className="row ">
                            {this.state.commerces ? this.state.commerces.map(commerce => <Commerce lang={this.props.lang} key={commerce._id} commerce={commerce} offertypelist={offerTypes} />) : ''}
                        </div>

                        <Link to={"/" + lang + "/search"} className="text-uppercase btn-primary tm-btn mx-auto tm-d-table"><Translate name="Show More Places" lang={lang} /></Link>
                    </div>
                </div>
            </div>
        );

    }

}

export default HomeListing;