import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const FilterListGroup = (props) => {
    const {items, activeItems, handleChange, name} = props;

    if (!items) return '';

    if (items.length === 0) return '';

    return ( 
        <React.Fragment>            
            <h3 className="m-0">{name}</h3>
            <ul className="list-group">
                {items.map(item => 
                item.count > 0 ? 
                    <li key={item.id} onClick={() => handleChange(item.id)} className={(activeItems.indexOf(item.id) > -1 ? "active " : "") + "filter-controls-items list-group-item"}>
                        {item.name} <input className="float-right" type="checkbox" checked={activeItems.indexOf(item.id) > -1 ? true : false} readOnly autoComplete="off" />
                        {activeItems.indexOf(item.id) > -1 ? <FontAwesomeIcon icon={['far', 'check-circle']} pull="right" size="lg" /> : ""}
                    </li>
                : ""
            )}
            </ul>
        </React.Fragment>      
     );
}
 
export default FilterListGroup;