import React, { Component } from 'react';
import { Config } from '../../config';
import axios from 'axios';
import PostSingleBasic from '../Content/PostSingleBasic';
import PostSinglePremium from '../Content/PostSinglePremium';

export default class Post extends Component {

    state = {
        isLoaded: false,
        error: false,
        post: false
    };

    componentDidMount() {
        axios.get(Config.apiPost + '/?slug=' + this.props.match.params.slug + (this.props.lang === 'en' ? "" : "&lang=" + this.props.lang ))
        .then(result => {
            if (result.data && result.data.length > 0){
                this.setState({ 
                    isLoaded: true, 
                    post: result.data
                });

                this.props.modifyOtherLangSlug(this.props.match.params.cat + "/" + result.data[0].other_lang_slug);
            } else {
                //Not found
                this.props.history.push('/not-found');
            }

        }).catch(error => {
            this.setState({
                isLoaded: false,
                error: error
            })
        });
    }

    render() {

        let { error, post } = this.state;
        let premium = 2; //default is standard  

        //Always start at top
        window.scrollTo(0 ,0);

        if (error) {
            return <div>Error: {error.message}</div>;
        }

        if(post){
            premium = post.map(function(item){
                return ( item.acf.premium );
            })[0];
        }
        
        return (
            <React.Fragment>
                {post && premium === '1' ? <PostSinglePremium lang={this.props.lang} post={post} /> : <PostSingleBasic lang={this.props.lang} post={post} />}
            </React.Fragment>
        )
    }
}
