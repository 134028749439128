import React, { Component } from 'react'

export default class NothingFound extends Component {
  render() {
    //Always start at top
    window.scrollTo(0 ,0);

    return (
      <React.Fragment>        
        <div className="page-404">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>404</h1>
                <p>Nothing Found</p>
                <p>Rien Trouvé</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
