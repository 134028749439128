import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputField = (props) => {
    const {formElementClass, fontAwesomeIcon, id, placeholder, children, specialStyle} = props;
    let displayChildren;

    if (!children) {
        displayChildren = <input name={id} type="text" className="form-control" id={id} placeholder={placeholder}></input> 
    } else {
        displayChildren = children;
    }

    return ( 
        <div className={`form-group tm-form-element ${formElementClass}`}>
            <FontAwesomeIcon style={specialStyle} className="tm-form-element-icon" icon={fontAwesomeIcon} size="lg" />
            {displayChildren}
        </div>
    );
}
 
export default InputField;