import React from 'react';
import Translate from './Translate';


const DetailBox = ({item, lang}) => {    
    return ( 
        <div className="details-box">
            <div className="details-box-inner">
                { 
                    item.acf.start_date !== "" && item.acf.end_date !== "" ?
                    <div>
                        <h3 className="detail-operation-label"><Translate name="Months of operation" lang={lang} /></h3>
                        <p>{item.acf.start_date} <Translate name="to" lang={lang} /> {item.acf.end_date}</p>
                    </div>
                    : ""
                }
                
                {
                    item.acf.business_hours !== "" ?
                    <div>
                        <h3 className="detail-hours-label"><Translate name="Business hours" lang={lang} /></h3>
                        <p dangerouslySetInnerHTML={{ __html: item.acf.business_hours}}></p>
                    </div> 
                    : ""
                }

                {
                    item.acf.location !== "" ?
                    <div>
                        <h3 className="detail-location-label"><Translate name="Location" lang={lang} /></h3>
                        <p dangerouslySetInnerHTML={{ __html: item.acf.location }}></p>
                    </div>
                    : ""
                }

                {
                    item.acf.phone_number !== "" || item.acf.website !== "" || item.acf.email !== "" ? 
                    <div>
                        <h3 className="detail-contact-label"><Translate name="Contact" lang={lang} /></h3>
                        <div>
                            {item.acf.phone_number !== "" ? <p><Translate name="Phone: " lang={lang} />{item.acf.phone_number}</p> : ""}
                            {item.acf.website !== "" ? <p style={{wordWrap: "break-word"}}><Translate name="Website: " lang={lang} /> {<a href={item.acf.website}>{item.acf.website}</a>}</p> : ""}
                            {item.acf.email !== "" ? <p style={{wordWrap: "break-word"}}><Translate name="Email: " lang={lang} />{item.acf.email}</p>: ""} <br />
                        </div>
                    </div>
                    : ""
                }
            </div>
            {
                item.acf.link_to_services && item.acf.link_to_services !== "" ?    
                <a href={item.acf.link_to_services} className="detail-btn"><Translate name="Reserve / Purchase / Info" lang={lang} /></a>
                : item.acf.website && item.acf.website !== "" ?    
                <a href={item.acf.website} className="detail-btn"><Translate name="Reserve / Purchase / Info" lang={lang} /></a>
                : ""
            }
        </div>

     );
}
 
export default DetailBox;