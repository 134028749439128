import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Config } from '../../config.js';
import ImageSrcSet from '../Common/imageSrcSet.jsx';
import Pagination from '../Common/Pagination.jsx';
import { Link } from 'react-router-dom';
import Translate from '../Common/Translate.jsx';
import queryString from 'query-string';

const BlogListing = (props) => {
    const [blogList, setBlogList] = useState([]);
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const {perPage, lang, history, location, pagination} = props;

    const handlePageChange = (page) => {
        //setLoaded(false);
        setCurrentPage(page);
        getArticles(page);

        if (history) {                                        
            history.push('/' + lang + '/article?page=' + page);
        }
    }

    const getArticles = (page = null) => {
        if (!page) {
            page = currentPage;
        }
        axios.get(Config.apiArticle + "?per_page=" + perPage + (lang === 'en' ? '' :'&lang=' + lang) + (page ? "&page=" + page : ""))
        .then(result => {
            if (result.data && result.data.length > 0){
                setLoaded(true);
                setBlogList( result.data );
                setMaxPages( Number( result.headers["x-wp-totalpages"] ) );
            } else {
                //Not found
                if (history) {
                    history.push('/not-found');
                }
            }
        }).catch(error => {
            setLoaded( false );
            setError( error );
        })
    }

    useEffect(() => {
        const queryData = location ? queryString.parse(location.search) : null;
        let page = null;
        if (queryData && queryData.page && queryData.page > 0) {
            setCurrentPage(Number( queryData.page ) );
            page = queryData.page;
        }

        if (loaded === false) {
            if ( page ) {
                getArticles( page );
            } else {
                getArticles();
            }
        }
    }, [blogList]);

    if( error ) {
        return (<div>error: {error.message}</div>);
    }

    return ( 
    <div className="container blog-list mt-5 mb-5">
        <div className="row">
            {blogList ? blogList.map(item => 
                <div key={item.id} className="col-12 col-md-4 mb-4 h-100">
                    <ImageSrcSet 
                        image={item.acf.header_image} 
                        maxsize={item.acf.header_image.sizes['medium-width']}
                        src={item.acf.header_image.sizes.medium}
                        smallSize={item.acf.header_image.sizes.medium}
                        mediumSize={item.acf.header_image.sizes.medium} 
                        largeSize={item.acf.header_image.sizes.medium}                        
                    />
                    <h3 className="tm-recommended-title pt-2 pb-2" dangerouslySetInnerHTML={{__html: item.title.rendered}}></h3>
                    <p dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}></p>
                    <Link className="text-uppercase btn-primary tm-btn-primary" to={"/" + lang + "/article/" + item.slug}><Translate name="See Article" lang={lang} /></Link>
                </div>
            )
            : ""
            }
        </div>

        {pagination ? 
        <Pagination 
        path={location.pathname} 
        query={location.search} 
        currentPage={currentPage} 
        pageSize={maxPages}
        onPageChange={handlePageChange}
        />
        : 
        <Link 
        className="text-uppercase btn-primary tm-btn mx-auto tm-d-table" 
        to={"/" + lang + "/article"}>
            <Translate name="More Articles" lang={lang} />
        </Link>
        }
    </div> );
}
 
export default BlogListing;