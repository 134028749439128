import React, { Component } from 'react'
import axios from 'axios'
import { Config } from '../../config.js';
import FilterListGroup from '../Common/FilterListGroup';
import { processAndRandomize } from '../Utils/RequestData.js';
import { GenerateQueryString } from '../Utils/generateQueryString';
import Pagination from '../Common/Pagination.jsx';
import SearchResult from '../Common/PostSearchResult';
import queryString from 'query-string';
import SearchForm from '../Form/SearchForm';
import moment from 'moment';
import Translate from '../Common/Translate';
import {getTranslated} from '../Utils/language.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Helmet} from 'react-helmet';

export default class Search extends Component {

    state = {
        error: null,
        isLoaded: false,
        rerunQuery: true,
        locationCategories: false,
        searchData: {
            //provinces: {queryLabel: "provinces", label: "Provinces", values: []}, //temporarily commented
            regions: {queryLabel: "regions", label: "Regions", values: []},
            offertype: {queryLabel: "category", label: "Categories", values: []},
            //city: {queryLabel: "city", label: "Cities", values: []},
            startdate: {queryLabel: "startdate", label: "", values: [], date: undefined},
            enddate: {queryLabel: "enddate", label: "", values: [], date: undefined},
        },        
        offerTypes: false,
        cities: false,
        allPosts: false,
        posts: false,
        currentPage: 1,
        totalPages: 1,
        query: "",
    };

    constructor(props) {
        super(props);

        const {lang} = this.props;

        //this.state.searchData.provinces.label = getTranslated(lang, "Provinces");//Temporarily Commented since we're just going to have regions for now
        this.state.searchData.regions.label = getTranslated(lang, "Regions");
        this.state.searchData.offertype.label = getTranslated(lang, "Categories");
        //this.state.searchData.city.label = getTranslated(lang, "Cities");
    }

    componentDidMount() {
        const {lang} = this.props;

        window.scrollTo(0,0);

        axios.get(Config.apiCategory + (lang === 'en' ? '?per_page=30' : "?lang=" + lang + "&per_page=30"))
        .then(result => {
            this.setState({
                isLoaded: true,
                locationCategories: result.data
            })                        
        }).catch(error => {    
            this.setState({
                isLoaded: false,
                error
            });            
        });

        axios.get(Config.apiOfferType + (lang === 'en' ? '' : "?lang=" + lang))
        .then(result => {
            this.setState({
                isLoaded: true,
                offerTypes: result.data
            });
        }).catch(error => {
            this.setState({
                isLoaded: false,
                error
            });
        });

        axios.get(Config.apiPost + (lang === 'en' ? '' : "?lang=" + lang))
        .then(result => {
            const postArray = processAndRandomize(result);
            let paginatedArray = false;
            if (postArray) {
                paginatedArray = postArray.slice(0, Config.numberSearchResultPerPage);
            }

            this.setState({ 
                isLoaded: true, 
                allPosts: postArray,
                posts: paginatedArray,
                totalPages: Number(result.data ? Math.ceil(result.data.length / Config.numberSearchResultPerPage) : 1),
            });
        }).catch(error => {
            this.setState({
                isLoaded: false,
                error: error
            })
        });        
    }

    handleProvinceChange = (item) => {
        let {searchData, currentPage} = this.state;

        const index = searchData.provinces.values.indexOf(item);

        if (index > -1){
            //if in list remove it
            searchData.provinces.values = searchData.provinces.values.filter(activeProvince => activeProvince !== item );
            //Remove/Reset subcategories as well
            searchData.regions.values = [];
        } else {
            searchData.provinces.values = [...searchData.provinces.values, item];
        }        

        //Don't consider the region if more than one province is selected
        if (searchData.provinces.values.length > 1)
            searchData.regions.values = [];

        this.setState({searchData, isLoaded: false});

        //Update the Posts.
        const queryString = GenerateQueryString(searchData, currentPage);
        
        if (this.props.lang === "fr") {
            this.props.history.push('/fr/recherche' + queryString);
        } else {
            this.props.history.push('/' + this.props.lang + '/search' + queryString);
        }
    }

    handleRegionChange = (item) => {
        let {searchData, currentPage} = this.state;

        const index = searchData.regions.values.indexOf(item);

        if (index > -1){
            //if in list remove it
            searchData.regions.values = searchData.regions.values.filter(activeProvince => activeProvince !== item );
        } else {
            searchData.regions.values = [...searchData.regions.values, item];
        }

        this.setState({searchData, isLoaded: false, rerunQuery: true});

        //Update the Posts.
        const queryString = GenerateQueryString(searchData, currentPage);
        
        if (this.props.lang === "fr") {
            this.props.history.push('/fr/recherche' + queryString);
        } else {
            this.props.history.push('/' + this.props.lang + '/search' + queryString);
        }
    }

    handleCategoryChange = (item) => {
        let {searchData, currentPage} = this.state;

        const index = searchData.offertype.values.indexOf(item);

        if (index > -1) {
            //if in list remove it
            searchData.offertype.values = searchData.offertype.values.filter(activeOffer => activeOffer !== item );
        } else {
            searchData.offertype.values = [...searchData.offertype.values, item];
        }

        this.setState({searchData, isLoaded: false, rerunQuery: true});
        
        //Update the Posts.
        const queryString = GenerateQueryString(searchData, currentPage);

        if (this.props.lang === "fr") {
            this.props.history.push('/fr/recherche' + queryString);
        } else {
            this.props.history.push('/' + this.props.lang + '/search' + queryString);
        }        
    }

    handleSearch = (data) => {        
        const {searchData} = this.state;

        //searchData.city.values = data.city.map(city => city.value);
        if (data.startdate) {
            searchData.startdate.values = [moment(data.startdate).format('YYYYMMDD')];
        } else {
            searchData.startdate.values = [];
        }
        
        if (data.enddate) {            
            searchData.enddate.values = [moment(data.enddate).format('YYYYMMDD')];
        } else {
            searchData.enddate.values = [];
        }

        this.setState({searchData, isLoaded: false, rerunQuery: true});
        
        //Update the Posts.
        const queryString = GenerateQueryString(searchData, 1);

        if (this.props.lang === "fr") {
            this.props.history.push('/fr/recherche' + queryString);
        } else {
            this.props.history.push('/' + this.props.lang + '/search' + queryString);
        }
    }    

    handlePageChange = (page) => {
        const {allPosts} = this.state;        
        this.setState({currentPage: page, isLoaded: false, posts: allPosts.slice((page - 1) * Config.numberSearchResultPerPage, page * Config.numberSearchResultPerPage)});

        //Whenever page change scroll to top
        window.scrollTo(0, 0);

        //Update the Posts.
        // const queryString = GenerateQueryString(searchData, page, false);

        // if (this.props.lang === "fr") {
        //     this.props.history.push('/fr/recherche' + queryString);
        // } else {
        //     this.props.history.push('/' + this.props.lang + '/search' + queryString);
        // }
    }

    render() {
        let { error, searchData, locationCategories, posts, offerTypes, currentPage, totalPages, isLoaded } = this.state;
        const {lang, location} = this.props;
        const parentProvinceCategory = lang === 'en' ? Config.parentProvinceCategory : Config.parentProvinceCategoryFr;
        
        //Set the posts according to the querystring
        const newSearchData = this.getPostData(this.props.location.search);

        const anBoutiqueImgSource = getTranslated(lang, "/img/anboutiquebox-en.jpg");
        const anBoutiqueLink = getTranslated(lang, "https://anboutique.ca/en/");

        if(error){
            return <div>Error: {error.message}</div>;
        }

        //let filteredProvinces = []; // Temporarily Commented
        let filteredRegions = [];
        let filteredOffersType = [];
        let filteredFormSearchData = {};
        //Format data so that search form is fill out properly in case of search from front page.
        
        if (searchData !== newSearchData && newSearchData){
            filteredFormSearchData = {
                //city : newSearchData.city.values ?
                //    newSearchData.city.values.map(item => ({value: item})): [],
                startdate: newSearchData.startdate.date ? newSearchData.startdate.date : undefined ,
                enddate: newSearchData.enddate.date ? newSearchData.enddate.date : undefined,
            };  
        } else {
            filteredFormSearchData = {
                //city : searchData.city.values ?
                //    searchData.city.values: [],
                startdate: searchData.startdate.date ? searchData.startdate.date : undefined,
                enddate: searchData.enddate.date ? searchData.enddate.date : undefined,
            };
        }        
        
        //Filter location categories.
        if (locationCategories){
            //filteredProvinces = locationCategories.filter(locationCategorie => locationCategorie.parent === parentProvinceCategory);   // Temporarily commented
            filteredRegions = locationCategories.filter(locationCategorie => locationCategorie.parent === parentProvinceCategory);
            // if (searchData.provinces.values && searchData.provinces.values.length <= 1) { //Temporarily commented
            //     filteredRegions = locationCategories.filter(province => searchData.provinces.values.indexOf(province.parent) > -1);
            // }        
        }        

        if (offerTypes){
            filteredOffersType = offerTypes;
        }

        return (
            <React.Fragment>
                {lang === "fr" ? 
                    <Helmet>                        
                        <title>Recherche - Visiti</title>
                        <meta name="description" content="Page de recherche d'établisement disponible sur le site." />
                    </Helmet>
                    :
                    <Helmet>
                        <title>Search - Visiti</title>
                        <meta name="description" content="Search page for businesses available on the site." />
                    </Helmet>
                }
         
                <div className="search-header">
                    <div className="container">
                        <h1><Translate name="Search-title" lang={lang} /></h1>
                    </div>
                    <div className="tm-section section-search">
                        <div className="container">
                            <div className="row">
                                <div className="box-search col-sm">
                                    <SearchForm lang={lang} handleSearch={this.handleSearch} hideDestinations={true} searchData={filteredFormSearchData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tm-search-result-filter-container container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-3">
                            <form className="box-filters" action="/search" method="get">
                                <fieldset data-filter-id="provinces">
                                    {/* <div className="filter-controls">
                                        <FilterListGroup 
                                        name={searchData.provinces.label} 
                                        items={filteredProvinces} 
                                        activeItems={searchData.provinces.values} 
                                        handleChange={this.handleProvinceChange}/>
                                    </div> */}
                                    <div className="filter-controls">
                                        <FilterListGroup 
                                        name={searchData.regions.label} 
                                        items={filteredRegions} 
                                        activeItems={searchData.regions.values} 
                                        handleChange={this.handleRegionChange} />
                                    </div>
                                    <div className="filter-controls">
                                        <FilterListGroup 
                                        name={searchData.offertype.label}
                                        items={filteredOffersType} 
                                        activeItems={searchData.offertype.values} 
                                        handleChange={this.handleCategoryChange} />
                                    </div>
                                </fieldset>
                            </form>
                            <a className="search-outside-link" rel="noopener noreferrer" target="_blank" href={anBoutiqueLink}><img style={{width:"100%"}} src={anBoutiqueImgSource} alt="link to AN Boutique" /></a>
                        </div>
                        <div className="box-result col-sm-12 col-lg-9">
                            {posts && isLoaded === true ? posts.map(post => <SearchResult lang={lang} key={post._id} commerce={post} offertypelist={offerTypes} searchPage={true} />) : <div style={{textAlign: "center", height: "85vh"}}><FontAwesomeIcon style={{color: "#006666"}} icon={["far", "sun"]} size="8x" spin /></div>}
                            {isLoaded ? <Pagination path={location.pathname} query={location.search} currentPage={currentPage} pageSize={totalPages} onPageChange={this.handlePageChange} /> : ""}
                        </div>                        
                    </div>
                </div>
            </React.Fragment>
        )
    }        

    getPostData = (query) => {        
        let {searchData, currentPage, allPosts, rerunQuery} = this.state;
        let totalPages = 1;
        let axiosQuery = "";

        if (query !== this.state.query) {
            //Adjust categories as well and page information as well.
            const {regions, category, page, startdate, enddate} = queryString.parse(query);//Temporarily removed provinces

            if (regions) {
                if(axiosQuery === ""){
                    axiosQuery = "?categories=" + regions;
                } else {
                    axiosQuery += '&categories=' + regions;
                }
                searchData.regions.values = regions.split(",").map(Number);
            } else {
                searchData.regions.values = [];
                // if (provinces) { //Temporarily Commented
                //     axiosQuery += '&categories=' + provinces;
                //     searchData.provinces.values = provinces.split(",").map(Number);
                // } else {
                //     searchData.provinces.values = [];
                // }
            }

            if(category) {
                if(axiosQuery === ""){
                    axiosQuery += '?offer_type=' + category;
                } else {
                    axiosQuery += '&offer_type=' + category;
                }
                searchData.offertype.values = category.split(",").map(Number);
            } else {
                searchData.offertype.values = [];
            }

            if(startdate && enddate) {
                if(axiosQuery === "") {
                    axiosQuery += '?startdate=' + startdate + '&enddate=' + enddate;
                } else {
                    axiosQuery += '&startdate=' + startdate + '&enddate=' + enddate;
                }
                searchData.startdate.date = moment(startdate, "YYYYMMDD").toDate();
                searchData.enddate.date = moment(enddate, "YYYYMMDD").toDate();
                searchData.startdate.values = [startdate];
                searchData.enddate.values = [enddate];
            } else {
                searchData.startdate.values = [];
                searchData.enddate.values = [];
            }

            // if(city) {
            //     axiosQuery += '&city=' + city;
            //     searchData.city.values = city.split(",").map(Number);
            // } else {
            //     searchData.city.values = [];
            // }

            if (page) {
                //axiosQuery += '&page=' + page;
                currentPage = Number(page);
            } else {
                currentPage = 1;
            }

            if(rerunQuery && currentPage <= 1){
                let query = "";
                if (axiosQuery === "") {
                    query = Config.apiPost + axiosQuery + (this.props.lang === 'en' ? '' : "?lang=" + this.props.lang);
                } else {
                    query = Config.apiPost + axiosQuery + (this.props.lang === 'en' ? '' : "&lang=" + this.props.lang);
                }

                axios.get( query )
                .then(result => {            
                    totalPages = result.data ? result.data.length / Config.numberSearchResultPerPage : 1;//Number(result.headers["x-wp-totalpages"]);
                    const postArray = processAndRandomize(result);
                    let paginatedArray = false;
                    if (postArray) {
                        paginatedArray = postArray.slice(0, Config.numberSearchResultPerPage);
                    }
                    this.setState({allPosts: processAndRandomize(result), posts: paginatedArray, totalPages, query, searchData, currentPage: 1, isLoaded: true, rerunQuery: false});
                }).catch(error => {
                    this.setState({error: error, isLoaded: true, query});
                });
            } else {
                let newPostarray = [];

                if ( allPosts && allPosts.length > 0) {
                    newPostarray = allPosts.slice((Number(currentPage) - 1) * Config.numberSearchResultPerPage, Number(currentPage) * Config.numberSearchResultPerPage);
                }

                this.setState({posts: newPostarray, query, currentPage, isLoaded: true});
            }

            return searchData;
        }
    }
}
