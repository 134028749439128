const Language = {
    "Other Language": {
        "fr": "English",
        "en": "Français"
    },
    "Discover your New Brunswick": {
        "en": "Discover your New Brunswick",
        "fr": "Découvrez votre Nouveau-Brunswick"
    },
    "View details": {
        "en": "View details",
        "fr": "Voir détails"
    },
    "Months of operation": {
        "en": "Months of operations",
        "fr": "Dates d'ouverture"
    },
    "Business hours": {
        "en": "Business hours",
        "fr": "Heures d'ouverture"
    },
    "Location": {
        "en": "Location",
        "fr": "Emplacement"
    },
    "Contact": {
        "en": "Contact",
        "fr": "Contact"
    },
    "Reserve / Purchase / Info": {
        "en": "Reserve / Purchase / Info",
        "fr": "Réservation / Achat / Infos"
    },
    "Type your destination": {
        "en": "Type your destination",
        "fr": "Insrivez votre destination"
    },
    "Type the regions": {
        "en": "Type the regions",
        "fr": "Inscrivez les régions"
    },
    "Arrival": {
        "en": "Arrival",
        "fr": "Début du séjour"
    },
    "Departure": {
        "en": "Departure",
        "fr": "Fin du séjour"
    },
    "to": {
        "en": "to",
        "fr": "au"
    },
    "Search": {
        "en": "Search",
        "fr": "Recherchez"
    },
    "Show More Places": {
        "en": "Show More Places",
        "fr": "Voir d'Autres Endroits"
    },
    "Nothing found": {
        "en": "Nothing found",
        "fr": "Rien Trouvé"
    },
    "Search-title": {
        "en": "Search",
        "fr": "Recherche"
    },
    "Provinces": {
        "en": "Provinces",
        "fr": "Provinces"
    },
    "Regions": {
        "en": "Regions",
        "fr": "Régions"
    },
    "Categories": {
        "en": "Categories",
        "fr": "Catégories"
    },
    "Cities": {
        "en": "Cities",
        "fr": "Villes"
    },
    "Phone: ": {
        "en": "Phone: ",
        "fr": "Téléphone : "
    },
    "Website: ": {
        "en": "Website: ",
        "fr": "Site Web : "
    },
    "Email: ": {
        "en": "Email: ",
        "fr": "Courriel : "
    },
    "Address: ": {
        "en": "Address:",
        "fr": "Adresse :"
    },
    "Would you like to display your business on our site?": {
        "en": "Would you like to display your business on our site?",
        "fr": "Vous voulez afficher votre entreprise sur notre site?"
    },
    "Please contact us at the following email": {
        "en": "Please contact us at the following email",
        "fr": "SVP contactez nous au courriel suivant"
    },
    "See Article": {
        "en": "See Article",
        "fr": "Voir l'article",
    },
    "More Articles": {
        "en": "More Articles",
        "fr": "Voir d'autres articles"
    },
    "/img/anboutiquebox-en.jpg": {
        "en": "/img/anboutiquebox-en.jpg",
        "fr": "/img/anboutiquebox.jpg"
    },
    "https://anboutique.ca/en/": {
        "en": "https://anboutique.ca/en/",
        "fr": "https://anboutique.ca/"
    },
    "Article Archive": {
        "en": "Article Archive",
        "fr": "Archive d'Article"
    }
}

export function getTranslated (language, text) {
    return ( Language[text] && Language[text][language] ? Language[text][language] : "" );
}