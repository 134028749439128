export function processAndRandomize(result){
    if(result.data){
        //Split by premium
        let resultArray = processPosts(result);

        let premium1 = resultArray.filter(item => item.premium === "1");
        let premium2 = resultArray.filter(item => item.premium === "2");
        let premium3 = resultArray.filter(item => item.premium === "3");

        return [...shuffleArray(premium1),...shuffleArray(premium2),...shuffleArray(premium3)];
    } else {
        return false;
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function processPosts(result) {    
    if (result.data) {        
        return result.data.map(post => ({
            _id: post.id,
            imageUrl: post.acf.image,
            title: post.title.rendered,
            city: post.acf.city,
            description: post.excerpt.rendered,
            price: post.acf.price,
            url: post.slug,
            offertype: post.offer_type,
            premium: post.acf.premium,
            location: post.acf.location,
            phone_number: post.acf.phone_number,
            start_date: post.acf.start_date,
            end_date: post.acf.end_date,
            website: post.acf.website
        }));
    } else {
        return false;
    }
}