import React, { Component } from 'react';
import Translate from '../Common/Translate';

export default class Footer extends Component {

    state = {
        year: false
    }

    componentDidMount() {
        this.setState({
            year: new Date().getFullYear()
        });
    }

    render() {
        let { year } = this.state;  

        return (
            <React.Fragment>
                <footer className="tm-bg-dark-black">
                    <div className="container">
                        <div className="row">
                            <p className="col-sm-12 text-center col-md-4 text-md-right offset-md-8 mt-4 tm-color-white">
                                <Translate name="Would you like to display your business on our site?" lang={this.props.lang} /><br />
                                <Translate name="Please contact us at the following email" lang={this.props.lang} /><br />
                                <a className="tm-color-white" href="mailto:publicite@acadienouvelle.com">publicite@acadienouvelle.com</a>
                            </p>
                        </div>
                        <div className="row">
                            <p className="col-sm-12 text-center tm-font-light tm-color-white p-4 tm-margin-b-0">
                            Copyright &copy; <span className="tm-current-year">{year}</span> Visiti</p>        
                        </div>
                    </div>                
                </footer>
            </React.Fragment>
        )
    }
}
