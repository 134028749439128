import React, { Component } from 'react';
import { Config } from '../../config.js';
import axios from 'axios';
import SearchForm from '../Form/SearchForm';
import { GenerateQueryString } from '../Utils/generateQueryString';
import moment from 'moment';

class HomeSearch extends Component {

    state = {        
        isLoaded: false,
        cities: false,
        error: null,
        searchData: {
            city: {queryLabel: "city", values: []},
            regions: {queryLabel: "regions", values: []},
            startdate: {queryLabel: "startdate", values: []},
            enddate: {queryLabel: "enddate", values: []}
        }
    };

    componentDidMount() {
        axios.get(Config.apiCities)
        .then(result => {
            this.setState({
                isLoaded: true,
                cities: result.data.map(item => ({value: String(item.id), label: item.name}))
            });
        }).catch(error=> {
            this.setState({
                isLoaded: false,
                error
            });
        })
    }

    handleSearch = data => {        
        const {searchData, currentPage} = this.state

        //searchData.city.values = data.city.map(city => city.value);
        searchData.regions.values = data.regions.map(region => region.value);
        if (data.startdate) {
            searchData.startdate.values = [moment(data.startdate).format('YYYYMMDD')];
        } else {
            searchData.startdate.values = [];
        }
        
        if (data.enddate) {            
            searchData.enddate.values = [moment(data.enddate).format('YYYYMMDD')];
        } else {
            searchData.enddate.values = [];
        }

        this.setState({searchData});

        //Update the Posts.
        const queryString = GenerateQueryString(searchData, currentPage);

        if (this.props.lang === "fr") {
            this.props.history.push('/fr/recherche' + queryString);
        } else {
            this.props.history.push('/' + this.props.lang + '/search' + queryString);
        }
    }

    render() {
        return (
            <div className="tm-section tm-bg-img" id="tm-section-1">
                <div className="tm-bg-white tm-section-search-container ie-container-width-fix-2">
                    <div className="container ie-h-align-center-fix">
                        <div className="row">
                            <div className="col-xs-12 ml-auto mr-auto ie-container-width-fix">
                                <SearchForm lang={this.props.lang} handleSearch={this.handleSearch} className="tm-section-pad-2" />
                            </div>                        
                        </div>      
                    </div>
                </div>                  
            </div>
        );

    }

}

export default HomeSearch;