// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faCalendarAlt, faSun, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

library.add(
  faCalendarAlt,
  faMapMarkerAlt,  
  faSun,
  faCheckCircle  
);