import React, { Component } from 'react';

import {
    Route,
    Switch    
} from 'react-router-dom';

import './App.css';
import Page from './app/components/Pages/Page';

class App extends Component {

    render() {
        return (
            <div className="tm-main-content" id="top">
                <div className="tm-top-bar-bg"></div>
            
                <Switch>
                    <Route path="/en" render={(props)=><Page {...props} lang="en" />} />
                    <Route path="/fr" render={(props)=><Page {...props} lang="fr"></Page>} />
                    <Route path="/" render={(props)=><Page {...props} lang="fr"></Page>} />
                </Switch>

            </div>
        );
    }
}

export default App;
