import React from 'react';
import {Link} from 'react-router-dom';
import Translate from './Translate';

const Commerce = (props) => {
    const {imageUrl, title, city, url, offertype} = props.commerce;
    let classes = "col-12 col-lg-3 col-sm-6 p-1";

    let offertypelist = props.offertypelist;
    let current_offer_type = 'post';

    for(let i = 0; i < offertypelist.length; i++){
        if(offertypelist[i].id === offertype[0]){
            current_offer_type = offertypelist[i].slug;
        }
    }

    return (
        <div className={classes}>
            <div className="card h-100">
                <div className="featured-full-width" style={{ backgroundImage : imageUrl.sizes && imageUrl.sizes.medium ? "url(" + imageUrl.sizes.medium + ")" : "", height: "225px" , backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center"  }} >
                    <img style={{display: "none"}} src={imageUrl.sizes && imageUrl.sizes.medium ? imageUrl.sizes.medium : ""} alt={imageUrl.alt} />
                </div>            
                <div className="card-body pb-0">
                    <h3 className="tm-recommended-title" dangerouslySetInnerHTML={{__html: title}}></h3>
                    <p className="tm-text-highlight">{city}</p>                
                </div>
                <div className="card-footer border-0 bg-white">
                    <Link to={'/' + props.lang + '/' + current_offer_type + '/' + url } className="text-uppercase btn-primary tm-btn-primary">
                        <Translate name="View details" lang={props.lang} />
                    </Link>
                </div>
            </div>
        </div>
    );
    
}
 
export default Commerce;