import React, {useEffect, useState} from 'react';

function getAspectRatio(image_url, imageSizes, sizes) {
    let currentSize;
    let ratio = "0";
    if (image_url && imageSizes && sizes) {
        //find the size
        imageSizes.forEach(size => {
            if (sizes[size] === image_url) {
                currentSize = size;
            }
        });

        //calculate the ratio
        ratio = (sizes[currentSize + "-height"] / sizes[currentSize + "-width"] * 100) + "%";
    }

    //return the padding percentage
    return ratio;
}

function setHeight ( image_url, imageSizes, sizes, height ) {
    let currentSize;
    let currentSetHeight = Number(height.replace(/[^-\d.]/g, ''));
    let setHeightValue = currentSetHeight;
    
    if (image_url && imageSizes && sizes) {
        //find the size
        imageSizes.forEach(size => {
            if (sizes[size] === image_url) {
                currentSize = size;
            }
        });

        //calculate the ratio
        setHeightValue = sizes[currentSize + "-height"];
    }

    //Check make sure that height is not bigger than the height set.
    if (setHeightValue > currentSetHeight) {
        setHeightValue = currentSetHeight;
    }

    //return the padding percentage
    return setHeightValue;
}

const ImageSrcSet = ({image, maxsize, src, smallSize, mediumSize, largeSize, height, maxWidth}) => {
    let {sizes} = image;
    let imageSizes = ["thumbnail", "medium", "medium_large", "large", "1536x1536", "2048x2048"];
    let srcSet = "";

    const mediaSmall = window.matchMedia('(max-width: 864px)');
    const mediaMedium = window.matchMedia('(min-width: 864px)');
    const mediaLarge = window.matchMedia('(min-width: 1200px)');

    const [small, setSmall] = useState(mediaSmall.matches);
    const [medium, setMedium] = useState(mediaMedium.matches);
    const [large, setLarge] = useState(mediaLarge.matches);

    const styles = {
        container: (small, medium, large) => ({
            backgroundImage: "url(" + (large ? largeSize : (medium ? mediumSize : (small ? smallSize : smallSize)))  + ")",
            height: (height ? setHeight((large ? largeSize : (medium ? mediumSize : (small ? smallSize : smallSize))), imageSizes, sizes, height) + "px" : 0),
            paddingTop: (height ? 0: getAspectRatio((large ? largeSize : (medium ? mediumSize : (small ? smallSize : smallSize))), imageSizes, sizes)),
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat", 
            backgroundSize: "cover",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: (maxWidth ? maxWidth : "")
        })
    };

    useEffect(() => {
        const handlerSmall = e => setSmall(e.matches);
        mediaSmall.addListener(handlerSmall);
        mediaSmall.removeListener(handlerSmall);

        const handlerMedium = e => setMedium(e.matches);
        mediaMedium.addListener(handlerMedium);
        mediaMedium.removeListener(handlerMedium);

        const handlerLarge = e => setLarge(e.matches);
        mediaLarge.addListener(handlerLarge);
        mediaLarge.removeListener(handlerLarge);
    });

    // "thumbnail": "https:\/\/api.visiti.ca\/wp-content\/uploads\/2020\/06\/miel-nbee-products-150x150.jpg",
    // "thumbnail-width": 150,
    // "medium": "https:\/\/api.visiti.ca\/wp-content\/uploads\/2020\/06\/miel-nbee-products.jpg",
    // "medium-width": 250,
    // "medium_large": "https:\/\/api.visiti.ca\/wp-content\/uploads\/2020\/06\/miel-nbee-products.jpg",
    // "medium_large-width": 250,
    // "large": "https:\/\/api.visiti.ca\/wp-content\/uploads\/2020\/06\/miel-nbee-products.jpg",
    // "large-width": 250,
    // "1536x1536": "https:\/\/api.visiti.ca\/wp-content\/uploads\/2020\/06\/miel-nbee-products.jpg",
    // "1536x1536-width": 250,
    // "2048x2048": "https:\/\/api.visiti.ca\/wp-content\/uploads\/2020\/06\/miel-nbee-products.jpg",
    // "2048x2048-width": 250,

    imageSizes.forEach(size => {
        if (Number( sizes[size + '-width'] ) <= Number( maxsize ) ) {
            srcSet += sizes[size] + " " + sizes[size + '-width'] + "w, ";
        }
    });
    //Remove last ", "
    if (srcSet) {
        srcSet = srcSet.substr(0, srcSet.length - 2);
    }    

    return ( 
        <div style={styles.container(small,medium,large)}>
            <img style={{display:"none"}} src={src} srcSet={srcSet} alt={image.alt}/>
        </div>
     );
}
 
export default ImageSrcSet;