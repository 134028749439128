import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import  {Link} from 'react-router-dom';
import queryString from 'query-string';

const Pagination = (props) => {
    const { pageSize, currentPage, onPageChange, path, query } = props;
    // [1... pagesCount].map()

    if (pageSize === 1 )  return null;
    const pages = _.range(1, pageSize + 1);
    let parsedquery = [];


    if (query)
        parsedquery = queryString.parse(query);

    return ( 
    <nav aria-label="Page navigation">
        <ul className="pagination">
            { pages.map(page => (<li key={page} className={page === currentPage ? "page-item active" : "page-item"}>
            <Link to={path + '?' + queryString.stringify({...parsedquery, page: page})} onClick= {() => onPageChange(page)} className="page-link">{page}</Link></li>))} 
        </ul>
    </nav> 
    );
}

Pagination.propTypes = {
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
};
 
export default Pagination;